import * as React from "react";
import {Link, PageProps } from "gatsby";
import * as styles from "./styles.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { Breadcrumb } from "../../../components/breadcrumb/breadcrumb";
import Layout from "../../../components/layout/layout";
import Container from "../../../components/container/container";
import Heading from "../../../components/basic-components/heading/heading";
import { ProductBar } from "../../../components/product-bar/product-bar";
import { Level } from "react-accessible-headings";
import { Helmet } from "react-helmet";
import blogImage from "../../../images/balgam.png";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { useEffect } from "react";
import scrollTo from "gatsby-plugin-smoothscroll";

const CoughGumPage: React.FC<PageProps> = ({ location }: PageProps) => {
  useEffect(() => {
    if (location.hash) {
      scrollTo(location.hash);
    }
  }, []);
  return (
    <Layout
      stickyHeader={false}
      seoConfig={{
        title: "Balgamlı Öksürük Nedir, Nedenleri Nelerdir?",
        description:
          "Balgamlı öksürük nedir, balgamlı öksürüğün nedenleri nelerdir? Balgamlı öksürük ve nedenleri hakkında daha detaylı bilgiye sahip olmak için sayfamızı ziyaret edin!",
      }}
    >
      <Helmet>
        <script type="application/ld+json">
          {`
            {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {"@type": "ListItem","position": 1,"name": "Ana Sayfa","item": "https://www.bisolnatur.com.tr/"},
                  {"@type": "ListItem","position": 2,"name": "Öksürük","item": "https://www.bisolnatur.com.tr/oksuruk/"},
                  {"@type": "ListItem","position": 3,"name": "Balgamlı Öksürük Nedir, Nedenleri Nelerdir?","item": "${location.href}"}
                ]
            }
          `}
        </script>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "BlogPosting",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${location.href}"
              },
              "headline": "Balgamlı Öksürük Nedir, Nedenleri Nelerdir?",
              "image": "${location.origin + blogImage}",  
              "author": {
                "@type": "Organization",
                "name": "Bisolnatur",
                "url": "${location.origin}"
              },  
              "publisher": {
                "@type": "Organization",
                "name": "Bisolnatur",
                "logo": {
                  "@type": "ImageObject",
                  "url": "${location.origin}/logo.svg"
                }
              },
              "datePublished": "2021-09-01",
              "dateModified": "2021-09-01"
            }
          `}
        </script>
        <script type="application/ld+json">{`{"@context":"https://schema.org","@type":"FAQPage","mainEntity":[{"@type":"Question","name":"Balgam Nedir?","acceptedAnswer":{"@type":"Answer","text":"Mukus, solunum yollarını nemli tutmak, bakteri, virüs ve yabancı cisimler gibi dış etkenleri solunum yollarından atmak için mukoza tarafından üretilen kaygan, sulu salgıdır."}},{"@type":"Question","name":"Balgamlı Öksürük Nedir?","acceptedAnswer":{"@type":"Answer","text":"Balgamın eşlik ettiği öksürüktür."}},{"@type":"Question","name":"Balgamlı Öksürük Nedenleri Nelerdir?","acceptedAnswer":{"@type":"Answer","text":"Solunum yolu enfeksiyonları, astım, kalp yetmezliği veya sigara tüketimi gibi faktörler balgamlı öksürüğe yol açabilir."}},{"@type":"Question","name":"Balgamlı Öksürüğe Ne İyi Gelir?","acceptedAnswer":{"@type":"Answer","text":"Balgamlı öksürükten kurtulmak için öncelikle öksürüğün nedeninin tespit edilmesi gerekir. Bal tüketmek, balgamın nemlendirilerek atılmasına yardımcı olabilir. Bal kendi başına ya da ılık bir içecekle karıştırılarak tüketilebilir. Balın 1 yaşından küçük çocuklara verilmesi önerilmez.\n"}}]}`}</script>
      </Helmet>
      <Breadcrumb
        pathname={location.pathname}
        noBg={true}
        className={styles.breadcrumb}
      />
      <Container fullWidth>
        <div className={classNames(styles.header)}>
          <StaticImage
            className={styles.image}
            src="../../../images/balgam.png"
            alt="Hasta kadın görseli"
            width={1680}
            height={450}
          />
          <div className={styles.filter} />
          <Container>
            <Heading>Balgamlı Öksürük Nedir, Nedenleri Nelerdir?</Heading>
            <ul>
              <li>
                <AnchorLink
                  className="blue"
                  to="#balgam-nedir"
                  title="Balgam Nedir?"
                >
                  Balgam Nedir?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#balgamli-oksuruk-nedir"
                  title="Balgamlı Öksürük Nedir?"
                >
                  Balgamlı Öksürük Nedir?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#balgamli-oksuruk-nedenleri-nelerdir"
                  title="Balgamlı Öksürük Nedenleri Nelerdir?"
                >
                  Balgamlı Öksürük Nedenleri Nelerdir?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#balgamli-oksuruge-ne-iyi-gelir"
                  title="Balgamlı Öksürüğe Ne İyi Gelir?"
                >
                  Balgamlı Öksürüğe Ne İyi Gelir?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#bisolnatur"
                  title="Bisolnatur"
                >
                  Bisolnatur
                </AnchorLink>
              </li>
              <li>
                <AnchorLink className="blue" to="#kaynakca" title="Kaynakça">
                  Kaynakça
                </AnchorLink>
              </li>
            </ul>
          </Container>
        </div>
      </Container>
      <Container>
        <div className={styles.holder}>
          <p className="extra-bold">
            Yaş öksürük olarak da bilinen balgamlı öksürük solunum yolları
            enfeksiyonuna bağlı olarak gelişen bir durumdur. Balgamlı öksürük
            birçok hastalığın belirtisi olabilir.
          </p>
          <StaticImage
            className={styles.image}
            src="../../../images/sick-woman-coughing-and-touching-throat-at-home-2021-04-05-22-56-22-utc@3x.png"
            alt="Hasta kadın görseli"
            width={792}
            height={348}
          />
          <section id="balgam-nedir" className={styles.whatHolder}>
            <Level>
              <Heading className="orange underline">Balgam Nedir?</Heading>
              <p>
                Mukus, solunum yollarını nemli tutmak, bakteri, virüs ve yabancı
                cisimler gibi dış etkenleri solunum yollarından atmak için
                mukoza tarafından üretilen kaygan, sulu salgıdır. Balgam ise
                genellikle enfeksiyonlara bağlı olarak solunum yollarında
                üretilen mukusun koyulaşmış halidir. Mukus herhangi bir sorun
                oluşturmaz, ancak solunum yollarında gelişen enfeksiyonlar veya
                başka hastalıklar nedeniyle balgam artarsa, rahatsız edici
                olabilir. Bu durumda, vücut üretilen fazla balgamı öksürük
                yoluyla dışarı atmaya çalışır.
              </p>
              <Heading id="balgamli-oksuruk-nedir" className="orange underline">
                Balgamlı Öksürük Nedir?
              </Heading>
              <p>
                Balgamın eşlik ettiği{" "}
                <Link className="blue underline" to={"/oksuruk/oksuruk-nedir/"}>
                  öksürüktür.
                </Link>
              </p>
              <Heading
                id="balgamli-oksuruk-nedenleri-nelerdir"
                className="orange underline"
              >
                Balgamlı Öksürük Nedenleri Nelerdir?
              </Heading>
              <p>
                Solunum yolu enfeksiyonları, astım, kalp yetmezliği veya sigara
                tüketimi gibi faktörler balgamlı öksürüğe yol açabilir.
              </p>
              <Heading
                id="balgamli-oksuruge-ne-iyi-gelir"
                className="orange underline"
              >
                Balgamlı Öksürüğe Ne İyi Gelir?
              </Heading>
              <p>
                Balgamlı öksürükten kurtulmak için öncelikle öksürüğün nedeninin
                tespit edilmesi gerekir. Bal tüketmek, balgamın nemlendirilerek
                atılmasına yardımcı olabilir.
                <br />
                Bal kendi başına ya da ılık bir içecekle karıştırılarak
                tüketilebilir. Balın 1 yaşından küçük çocuklara verilmesi
                önerilmez.
                <br />
                <br />
                Bol sıvı ile birlikte bitki çaylarının tüketilmesi, vücudun
                susuz bırakılmaması da balgamlı öksürüğü rahatlatmak için
                önerilir.
              </p>
            </Level>
          </section>
          <section className={styles.bisolnatur}>
            <Level>
              <Heading id="bisolnatur">
                Bisolnatur ile Öksürüğünü Rahatlat
              </Heading>
              <div className={classNames("flexbox", styles.productHolder)}>
                <div className={styles.content}>
                  <p>
                    Bisolnatur renklendirici, koruyucu, tatlandırıcı, paraben
                    gibi hiçbir katkı maddesi içermez. Aynı zamanda, glüten de
                    içermediği için çölyak hastaları tarafından güvenle
                    kullanılabilir.
                    <br />
                    <br />
                    Bisolnatur içerdiği doğal şeftali, limon ve portakal
                    aromaları ile hoş bir tada sahiptir. T.C Sağlık Bakanlığı
                    Türkiye İlaç Tıbbi Cihaz Kurumu’na (TİTCK) kayıtlı olan
                    Bisolnatur, 1 yaşından büyük herkes tarafından
                    kullanılabilir. Hamilelik ve emzirme dönemlerinde ise
                    doktora danışılarak kullanılabilir.
                  </p>
                </div>
                <StaticImage
                  className={styles.image}
                  src="../../../images/bisolnatur-urun-yeni.png"
                  alt="Bisolnatur ürün görseli"
                  width={584}
                  height={584}
                />
              </div>
              <Level>
                <p>
                  Bisolnatur, doğal içeriğiyle boğazda koruyucu bir tabaka
                  oluşturarak, boğazın tahriş olmasını engeller ve kuru öksürüğü
                  rahatlatır. Aynı zamanda, balgamlı öksürükte de balgamı
                  yumuşatarak kolayca atılmasını sağlar.
                  <br />
                  <br />
                  Bisolnatur’u kullanmadan önce kullanım kılavuzu dikkatle
                  okunmalıdır. Doğru şekilde kullanım için, şişe açılmadan önce
                  iyice çalkalanmalı ve kutu ile birlikte verilen kaşık
                  kullanılmalıdır.
                  <br />
                  <br />
                  <b>
                    Hamile ve emzirenler doktora danışarak Bisolnatur’ü
                    kullanabilir.
                  </b>
                  <br />
                  <br />
                  Tüketiciler için önerilen kullanım dozları şu şekildedir (bir
                  doz=bir ölçü kaşığı):
                </p>
                <ul>
                  <li className="blue">
                    <b>1-6 yaş arasındaki çocuklarda</b>, 1 ölçü kaşığı (5 ml)
                    olmak üzere günde iki doz
                  </li>
                  <li className="blue">
                    <b>6 yaş ve üzerindeki çocuklarda</b> 2 ölçü kaşığı (10 ml)
                    olmak üzere günde iki doz
                  </li>
                  <li className="blue">
                    <b>Yetişkinlerde</b> ise 2 ölçü kaşığı (10 ml) olmak üzere
                    günde iki doz (Aynı zamanda, yetişkinlerde, tüketicinin
                    gerekli gördüğü durumlarda kısa aralıklarla 4 defaya kadar
                    kullanılması mümkündür.)
                  </li>
                </ul>
                <p>
                  Sağlık Bakanlığı’na kayıtlı olan Bisolnatur’ün 1 yaş üzeri
                  çocuklar için kullanımı uygundur. Yetişkinlerde veya
                  çocuklarda uyku hâline sebep olmaz. Bisolnatur’ün
                  kullanımında, öksürük belirtilerinin bir hafta içinde
                  iyileşmemesi hâlinde doktora veya eczacıya başvurulması
                  önerilir.
                  <br />
                  <br />
                  Bisolnatur; serin, kuru ve çocukların ulaşamayacağı bir yerde
                  saklanmalıdır. Direkt olarak güneş ışığına maruz
                  bırakılmamalıdır. Şişesi açıldıktan sonra 3 ay içinde
                  tüketilmelidir.
                </p>
                <Heading id="kaynakca">Kaynakça</Heading>
                <p>
                  1.
                  https://www.health.harvard.edu/diseases-and-conditions/cracking-the-cough-code#:~:text=of%20the%20cough.,Wet%20cough,flu%2C%20bronchitis%2C%20or%20pneumonia.
                </p>
                <p>
                  2. https://pubmed.ncbi.nlm.nih.gov/30584049/
                </p>
                <p>
                  3. https://pubmed.ncbi.nlm.nih.gov/18435475/
                </p>
              </Level>
            </Level>
          </section>
        </div>
        <div className="date">
          <p>Yayınlama Tarihi: 2021-09-01</p>
          <p>Son güncellenme Tarihi: 2021-09-01</p>
        </div>
      </Container>
      <ProductBar />
    </Layout>
  );
};
export default CoughGumPage;
